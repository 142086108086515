<template>
  <div>
    <!-- pic2247681F39E38F535F13E3F036F1133A.jpg -->
    <img class="rounded img-large" src="">
    <p>
      The LEGO Universe News Network recently obtained this urgent message from Brickkeeper!
    </p>
    <p>
      It comes from a top-secret source who is close with the Professor:
    </p>
    <p>
      <i>Dear Earth Friends,</i>
    </p>
    <p>
      <i>I fear the power of the Maelstrom still grows! The dark menace must be stopped before more creativity is destroyed! Your bright imagination is what my Universe needs now!</i>
    </p>
    <p>
      <i>Help us focus on fun!  Get into the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '4B1FD5EE-EBD5-4016-A820-D92ECACCE36B'} }">Creation Lab</router-link> and put together a great party for your friends!</i>
    </p>
  </div>
</template>
